import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils';

const forms = document.querySelectorAll('[data-form]');

forms.forEach((form) => {
  const submitBtn = form.querySelector('[data-form="submit"]');
  const inputs = form.querySelectorAll('[data-form="input"]');
  const checkboxes = form.querySelectorAll('[data-form="checkbox"]');
  const phoneInputs = form.querySelectorAll('[data-form="phone"]');

  phoneInputs.forEach((input) => {
    const iti = intlTelInput(input, {
      initialCountry: 'auto',
      strictMode: true,
      separateDialCode: true,
      geoIpLookup: (callback) => {
        fetch('https://ipapi.co/json')
          .then((res) => res.json())
          .then((data) => callback(data.country_code))
          .catch(() => callback('ua'));
      },
    });

    input.addEventListener('open:countrydropdown', () => {
      const dropdownRect = iti.dropdownContent.getBoundingClientRect();
      const { innerHeight } = window;

      if (dropdownRect.bottom > innerHeight) {
        iti.dropdownContent.style.bottom = '100%';
        iti.dropdownContent.style.marginBottom = '3px';
      }
    });

    input.addEventListener('close:countrydropdown', () => {
      iti.dropdownContent.style.bottom = null;
      iti.dropdownContent.style.marginBottom = null;
    });
  });

  const requiredInputs = [...inputs].filter((input) => input.required);
  const requiredCheckboxes = [...checkboxes].filter(
    (checkbox) => checkbox.required
  );

  const isInputsFilled = (inputs) => {
    return inputs.every((input) => input.value !== '');
  };
  const isCheckboxesTrue = (checkboxes) => {
    return checkboxes.every((checkbox) => checkbox.checked);
  };

  const isPhoneInputsValid = (inputs) => {
    return [...inputs].every((input) => {
      const iti = intlTelInput.getInstance(input);
      console.dir(iti.dropdownContent);
      return iti.isValidNumber();
    });
  };

  const isValidFormData = () => {
    return (
      isInputsFilled(requiredInputs) &&
      isPhoneInputsValid(phoneInputs) &&
      isCheckboxesTrue(requiredCheckboxes)
    );
  };

  if (!isValidFormData()) {
    submitBtn.disabled = true;
  }

  [...requiredInputs, ...phoneInputs].forEach((input) => {
    input.addEventListener('input', () => {
      if (!isValidFormData()) {
        submitBtn.disabled = true;
      } else {
        submitBtn.disabled = false;
      }
    });
  });

  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener('change', () => {
      if (!isValidFormData()) {
        submitBtn.disabled = true;
      } else {
        submitBtn.disabled = false;
      }
    });
  });

  submitBtn?.addEventListener('click', (e) => {
    e.preventDefault();
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    console.log(data);
  });
});
