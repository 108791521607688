import Plyr from 'plyr';

const videos = document.querySelectorAll('[data-plyr]');
const plyrs = [];

videos.forEach((video) => {
  const player = new Plyr(video, {
    quality: {
      forced: true,
      default: 1080,
      options: [1080, 720, 480, 360],
    },
    captions: {
      active: false,
    },
  });
  console.log(player);

  plyrs.push(player);
});

export const pauseAllPlayers = () => {
  plyrs.forEach((player) => {
    player.pause();
  });
};
