import gsap from 'gsap';
import { api } from '../../libs/axios.lib';
import { notify } from '../../utils/notify.utils';
import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils';

const contactForm = document.querySelector('.contact-form');

let b1 =
  'linear-gradient(216deg, #ffffff 25.97%, rgba(255, 255, 255, 0) 36.63%, rgba(255, 255, 255, 0) 67.82%, #ffffff 78.13%)';
let b2 =
  'linear-gradient(144deg, #ffffff 25.97%, rgba(255, 255, 255, 0) 36.63%, rgba(255, 255, 255, 0) 67.82%, #ffffff 78.13%)';

if (contactForm) {
  gsap
    .timeline({ repeat: -1, yoyo: 'true', repeatDelay: 3 })
    .add(gsap.set(contactForm, { background: b1 }))
    .add(gsap.to(contactForm, { ease: 'none', duration: 6, background: b2 }))
    .play(0);

  // Form

  const submitBtn = contactForm.querySelector('#submit-contact-us');
  const nameInput = contactForm.querySelector('#name');
  const phoneInput = contactForm.querySelector('#phone');
  const agreeCheckbox = contactForm.querySelector('#agree-checkbox');
  const tariffBtns = document.querySelectorAll('.tariff .btn');

  const iti = intlTelInput(phoneInput, {
    initialCountry: 'auto',
    strictMode: true,
    separateDialCode: true,
    excludeCountries: ['ru', 'by'],
    geoIpLookup: (callback) => {
      fetch('https://ipapi.co/json')
        .then((res) => res.json())
        .then((data) => callback(data.country_code))
        .catch(() => callback('ua'));
    },
  });

  let selectedTariff = null;

  tariffBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      const url = new URL(window.location.href);
      url.searchParams.set('tariff', btn.dataset.tariff);
      window.history.pushState(null, '', url.toString());
    });
  });

  agreeCheckbox.addEventListener('change', () => {
    if (agreeCheckbox.checked) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  });

  submitBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    try {
      submitBtn.classList.add('is-loading');

      if (!iti.isValidNumber()) throw new Error('Invalid phone number');

      const name = nameInput.value;
      const phone = iti.getNumber();

      // const foundContacts = await api.get(`/contact?query=${phone}`);
      // if (foundContacts?.data) {
      //   console.log('Contact allready exists!');
      //   notify('Contact allready exists!', 'error');
      //   throw new Error('Contact allready exists!');
      // }

      const newContact = await api.post('/contact', {
        name,
        phone,
      });

      const newLead = await api.post('/lead', {
        name: 'Contact us',
        phone,
        pipeline_id: 8243187,
      });

      await api.post('/lead-contact-link', {
        lead_id: newLead.data._embedded.leads[0].id,
        contact_id: newContact.data._embedded.contacts[0].id,
      });

      await api.post('/lead/user-data', {
        lead_id: newLead.data._embedded.leads[0].id,
        link: window.location.href,
        name: name,
        phone: phone,
        tariff: selectedTariff ?? '-',
      });

      nameInput.value = '';
      phoneInput.value = '';

      // window.gtag('event', 'contact-us', { phone, name });

      notify('Feedback sent successfully!', 'info');

      window.open('/thank-you', '_self');
    } catch (error) {
      console.dir(error);
      notify(`Error! \n ${error.message}`, 'error');
    } finally {
      submitBtn.classList.remove('is-loading');
    }
  });
}
