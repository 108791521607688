// Light animation by scroll
const aiv = ({ once, selector } = { once: false, selector: null }) => {
  const animateInViewElements = document.querySelectorAll('[data-aiv]');
  const animateInViewElementsBySelector = document.querySelectorAll(selector);

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');

          if (once) observer.unobserve(entry.target);
        } else if (!once) {
          entry.target.classList.remove('in-view');
        }
      });
    },
    { threshold: 0.25 }
  );

  [...animateInViewElements, ...animateInViewElementsBySelector].forEach(
    (item) => {
      const { dataset } = item;
      item.style.transition = `all ease ${dataset.aivTime || 500}ms ${
        dataset.aivDelay || 0
      }ms`;

      observer.observe(item);
    }
  );
};

export default aiv;
