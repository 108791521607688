const { pauseAllPlayers } = require('../libs/plyr.lib');

const body = document.querySelector('body');
const popupOpenBtns = document.querySelectorAll('[data-popup-open]');

export const popupOpen = (id) => {
  const popup = document.querySelector(`#${id}`);

  if (popup) {
    popup.classList.add('is-open');
  } else {
    throw new Error('Popup not found');
  }
};

popupOpenBtns.forEach((btn) => {
  btn.addEventListener('click', () => {
    const popupId = btn.getAttribute('data-popup-open');

    popupOpen(popupId);

    body.style.overflow = 'hidden';
  });
});

const popupCloseBtns = document.querySelectorAll('[data-popup-close]');

popupCloseBtns.forEach((btn) => {
  btn.addEventListener('click', () => {
    const popupId = btn.getAttribute('data-popup-close');
    const popup = document.querySelector(`#${popupId}`);

    if (popup) {
      popup.classList.remove('is-open');

      pauseAllPlayers();

      body.style.overflow = '';
    } else {
      throw new Error('Popup not found');
    }
  });
});
