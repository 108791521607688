import {
  addMonths,
  startOfMonth,
  isWeekend,
  addDays,
  endOfMonth,
  differenceInDays,
} from 'date-fns';

const tariffsSection = document.querySelector('.tariffs-section');

if (tariffsSection) {
  const tariffIndividual = tariffsSection.querySelector('#tariff-individual');
  const tariffTeam = tariffsSection.querySelector('#tariff-team');

  if (tariffTeam) {
    const MAX_PLACES = 20;
    const placesRef = tariffTeam.querySelector('[data-tariff-places]');
    const dateRef = tariffTeam.querySelector('[data-tariff-date]');
    const nextCourseDateStart = getFirstWorkingDayNextMonth();

    dateRef.textContent = nextCourseDateStart.toLocaleDateString('uk-UA', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    const dates = splitMonthInto7Parts();

    const placesByIdx = {
      0: 2,
      1: 5,
      2: 7,
      3: 12,
      4: 14,
      5: 16,
      6: 19,
    };

    const currentPlaceIndex = dates.findLastIndex((date) => {
      return Date.now() > date.getTime();
    });

    placesRef.textContent = placesByIdx[currentPlaceIndex] ?? MAX_PLACES - 1;
  }

  if (tariffIndividual) {
    const MAX_PLACES = 10;
    const placesRef = tariffIndividual.querySelector('[data-tariff-places]');

    const dates = splitMonthInto7Parts();

    const placesByIdx = {
      0: 1,
      1: 2,
      2: 4,
      3: 6,
      4: 7,
      5: 8,
      6: 9,
    };

    const currentPlaceIndex = dates.findLastIndex((date) => {
      return Date.now() > date.getTime();
    });

    placesRef.textContent = placesByIdx[currentPlaceIndex] ?? MAX_PLACES - 1;
  }
}

function getFirstWorkingDayNextMonth() {
  let nextMonth = addMonths(new Date(), 1);
  let firstDayNextMonth = startOfMonth(nextMonth);

  while (isWeekend(firstDayNextMonth)) {
    firstDayNextMonth = addDays(firstDayNextMonth, 1);
  }

  return firstDayNextMonth;
}

function splitMonthInto7Parts() {
  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());

  const totalDaysInMonth = differenceInDays(end, start) + 1;

  const daysPerSegment = Math.floor(totalDaysInMonth / 7);

  const dates = [];

  for (let i = 0; i < 6; i++) {
    const currentDate = addDays(start, i * daysPerSegment);
    dates.push(currentDate);
  }

  dates.push(end);

  return dates;
}
