import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils';
import { api } from '../../libs/axios.lib';
import { notify } from '../../utils/notify.utils';
import { popupOpen } from '../popups.component';

const callMe = document.querySelector('.call-me');

if (callMe) {
  const callMeInputName = callMe.querySelector('#call-me-name');
  const callMeInputPhone = callMe.querySelector('#call-me-phone');
  const callMeSubmit = callMe.querySelector('.call-me__submit-btn');

  const callMeBtn = callMe.querySelector('.call-me__btn');
  callMeBtn.addEventListener('click', () => {
    if (window.innerWidth < 768) {
      popupOpen('call-me-popup');
    } else {
      callMe.classList.toggle('active');
    }
  });

  callMeSubmit.addEventListener('click', async (e) => {
    e.preventDefault();
    try {
      callMeSubmit.classList.add('is-loading');
      const iti = intlTelInput.getInstance(callMeInputPhone);

      const name = callMeInputName.value;
      const phone = iti.getNumber();

      const newContact = await api.post('/contact', {
        phone,
        name,
      });

      const newLead = await api.post('/lead', {
        name: 'Call me',
        phone,
        pipeline_id: 8243187,
      });

      await api.post('/lead-contact-link', {
        lead_id: newLead.data._embedded.leads[0].id,
        contact_id: newContact.data._embedded.contacts[0].id,
      });

      callMe.classList.remove('active');
      callMeInputPhone.value = '';
      callMeInputName.value = '';

      // window.gtag('event', 'call-me', { phone, name });

      notify('Ваша заявка прийнята!');
    } catch (error) {
      console.error(error);
      notify('Помилка', 'error');
    } finally {
      callMeSubmit.classList.remove('is-loading');
    }
  });
}

const callMePopup = document.querySelector('#call-me-popup');

if (callMePopup) {
  const callMePopupInputName = callMePopup.querySelector('#call-me-popup-name');
  const callMePopupInputPhone = callMePopup.querySelector(
    '#call-me-popup-phone'
  );
  const callMePopupSubmit = callMePopup.querySelector('#submit-call-me-popup');

  callMePopupSubmit.addEventListener('click', async (e) => {
    e.preventDefault();
    try {
      callMePopupSubmit.classList.add('is-loading');

      const name = callMePopupInputName.value;
      const phone = callMePopupInputPhone.value;

      const newContact = await api.post('/contact', {
        name,
        phone,
      });

      const newLead = await api.post('/lead', {
        name: 'Call me form',
        phone,
        pipeline_id: 8243187,
      });

      await api.post('/lead-contact-link', {
        lead_id: newLead.data._embedded.leads[0].id,
        contact_id: newContact.data._embedded.contacts[0].id,
      });

      callMePopup.classList.remove('is-open');
      callMePopupInputName.value = '';
      callMePopupInputPhone.value = '';

      notify('Ваша заявка принята!');
    } catch (error) {
      console.error(error);
      notify('Помилка', 'error');
    } finally {
      callMePopupSubmit.classList.remove('is-loading');
    }
  });
}
